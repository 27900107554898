import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router';

import { getHeadings } from '../../components/Layout/TableOfContents/lib/helpers';

import { BackToTop } from '../../components/BackToTop';
import Contact from '../../components/contact';
import ChildNavigationFooter from '../../components/docs/core/ChildNavigationFooter';
import EditorialDocumentationNavigation from '../../components/docs/core/EditorialDocumentationNavigation';

import { Sections, Layout, TableOfContentsDesktop, Article } from '../../components/Layout';
import { SectionContainer } from './SectionContainer';

export const Page = ({ context, pkg, content }) => {
  const { edges } = content;
  const [headings, setHeadings] = useState([]);
  const layoutRef = useCallback(node => {
    if (!node) {
      setHeadings([]);

      return;
    }

    setHeadings(getHeadings(node));
  }, []);

  let _navigation_footer = <ChildNavigationFooter />;

  if (
    globalHistory.location.pathname === '/develop/guidelines' ||
    globalHistory.location.pathname === '/design/guidelines'
  ) {
    _navigation_footer = <EditorialDocumentationNavigation />;
  }

  let _edges = edges;

  // To make sure that for the given page, we do not need any branch stuff
  if (
    globalHistory.location.pathname === '/components' ||
    globalHistory.location.pathname === '/design' ||
    globalHistory.location.pathname === '/design/foundation' ||
    globalHistory.location.pathname === '/develop'
  ) {
    _edges = edges.filter(edge => !edge.node.frontmatter.branch);
  }

  return (
    <Layout hasSidebar={true} header={true} ref={layoutRef}>
      <Article>
        <Sections>
          {_edges.map((edge, index) => (
            <SectionContainer key={`section-${index}`} context={context} edge={edge} edges={edges} pkg={pkg} />
          ))}
        </Sections>
        {_navigation_footer}
        <BackToTop />
        <Contact />
      </Article>
      <TableOfContentsDesktop headings={headings} />
    </Layout>
  );
};

Page.propTypes = {
  pkg: PropTypes.shape({
    description: PropTypes.string
  }),
  context: PropTypes.shape(),
  content: PropTypes.shape({
    edges: PropTypes.array
  }),
  wide: PropTypes.bool
};
