import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { generateID } from '../../lib/helpers';
import { getComponentPackage } from '../../lib/helpers';

import EditLink from '../../components/editLink';
import {
  Title,
  NoDocumentationBlock,
  DocumentationContainer,
  Section,
  MDXBlock,
  ParagraphLead,
  TableOfContentsMobile
} from '../../components/Layout';
import { LinkedPackagesNavigation } from '../../components/Component';

export const SectionContainer = ({ edges, context, edge, pkg /*wide*/ }) => {
  const { node } = edge;
  const { fields = {}, fileAbsolutePath, frontmatter, html, body } = node;
  const { linkedPackages } = context || { linkedPackages: {} };
  const { collection = '' } = fields;
  const { order, componentName, customPath, title } = frontmatter;
  const _is_changelog = fileAbsolutePath && fileAbsolutePath.indexOf('CHANGELOG.md') !== -1;

  let _custom_path = customPath;
  let _title = title;

  const _documentation_body = <div className="if" dangerouslySetInnerHTML={{ __html: html }} />;

  if (_is_changelog) {
    _custom_path = fields.customPath;
    _title = 'Changelog';
  }

  const _id = generateID(_custom_path, _title);
  const _component_package = getComponentPackage(componentName, pkg);
  const _is_first_section_of_component = !!componentName && _component_package && order === 1;
  const _is_missing_documentation = !body && html.length === 0 && !_is_first_section_of_component;
  const _section_class_names = cx('ids-doc sg if section poc', {
    'is-changelog': _is_changelog
  });

  if (_is_missing_documentation) {
    return (
      <NoDocumentationBlock
        className={_section_class_names}
        collection={collection}
        frontmatter={frontmatter}
        fields={fields}
        isCSSPackage={false}
        isJSPackage={false}
        isWCPackage={false}
        componentPackage={_component_package}
        path={fileAbsolutePath}
      />
    );
  } else if (body) {
    return (
      <MDXBlock className={_section_class_names} id={_id}>
        {!!customPath && _is_first_section_of_component && (
          <a
            href="#"
            role="button"
            onClick={e => {
              e.preventDefault();
              history.back();

              return false;
            }}
            className="if back-to-link u-hidden-up--md"
            style={{ marginBottom: '2rem', display: 'inline-block' }}>
            Back to components
          </a>
        )}

        <Title
          collection={collection}
          frontmatter={frontmatter}
          fields={fields}
          pkg={_component_package}
          fileAbsolutePath={fileAbsolutePath}
        />
        {_is_first_section_of_component && (
          <div className="if text layout column left">
            <div className="if text body">
              <ParagraphLead>{_component_package.description}</ParagraphLead>
            </div>
          </div>
        )}
        {order === 1 && <LinkedPackagesNavigation customPath={customPath} packages={linkedPackages[componentName]} />}
        <MDXRenderer>{body}</MDXRenderer>
        {order === 1 && <TableOfContentsMobile edges={edges} />}
        <EditLink path={fileAbsolutePath} />
      </MDXBlock>
    );
  }

  const _section_ref = React.createRef();

  // @todo remove this when we have removed all the manual entries in the markdown files and created the mdx variant
  // @deprecated remove in v17
  React.useEffect(() => {
    if (order === 1) {
      const _quick_links_el = _section_ref.current.querySelector('.if.quick-links:not(.ids-doc)');

      if (_quick_links_el) {
        if (!_quick_links_el.closest('.global-header')) {
          _quick_links_el.remove();
        }
      }
    }
  }, []);

  return (
    <Section ref={_section_ref} className={_section_class_names} id={_id}>
      <DocumentationContainer>
        {!!customPath && _is_first_section_of_component && (
          <a
            href="#"
            role="button"
            onClick={e => {
              e.preventDefault();
              history.back();

              return false;
            }}
            className="if back-to-link u-hidden-up--md"
            style={{ marginBottom: '2rem', display: 'inline-block' }}>
            Back to components
          </a>
        )}

        <Title
          collection={collection}
          frontmatter={frontmatter}
          fields={fields}
          pkg={_component_package}
          fileAbsolutePath={fileAbsolutePath}
        />
        {_is_first_section_of_component && (
          <div className="if text layout column left">
            <div className="if text body">
              <ParagraphLead>{_component_package.description}</ParagraphLead>
            </div>
          </div>
        )}
        {order === 1 && <LinkedPackagesNavigation customPath={customPath} packages={linkedPackages[componentName]} />}
        {_documentation_body}
        {order === 1 && <TableOfContentsMobile edges={edges} />}
        <EditLink path={fileAbsolutePath} />
      </DocumentationContainer>
    </Section>
  );
};

SectionContainer.propTypes = {
  fullPage: PropTypes.bool,
  wide: PropTypes.bool,
  edges: PropTypes.array,
  edge: PropTypes.shape(),
  context: PropTypes.shape({
    linkedPackages: PropTypes.shape({})
  }),
  pkg: PropTypes.shape()
};

SectionContainer.displayName = 'SectionContainer';
